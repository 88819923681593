const itemList = document.getElementById('notificationListHeader');
const notificationsCounter = document.getElementById('notificationsCounter');

const notifications = () => {
    const fetchNotifications = async () => {
        const fetchResponse = await fetch(window.__abuse.urls.notificationsUrl, {
            method: 'POST',
        });

        const result = await fetchResponse.json();

        if (fetchResponse.status === 200) {
            console.log(result.notifications);

            var count = notificationsCounter.innerHTML;
            notificationsCounter.innerHTML = parseInt(count) + result.numberOfUnshown;

            result.notifications.forEach((notif)=>{
                var node = document.createElement('div');
                node.className = 'notification-preview__item js-notification';
                node.innerHTML = '<div class="notification-preview__ico"><i class="material-icons">calendar_today</i></div>\n' +
                    '                        <div class="notification-preview__body">\n' +
                    '                            <div class="notification-preview__date" data-role="date">'+notif.createdAt+'</div>\n' +
                    '                            <div class="notification-preview__message" data-role="message">'+notif.message+'</div>\n' +
                    '                        </div>\n'
                itemList.prepend(node);
                $.notify(notif.message, 'success');
            })
        } else if (fetchResponse.status === 400) {

        }
    }
    setInterval(() => { fetchNotifications(); }, 60*1000);
}
export {
    notifications
};

